// @ts-nocheck
import { useState, useEffect } from "react";
import _ from "lodash";
import { AdverseMediaHit as AdverseMediaHitType, CommandResult, SanctionHit, StatusMessage } from "@/types";
import Alert from "@/components/TailwindComponents/Alert";
import GovernmentIDCard from "@/components/TailwindComponents/GovernmentIDCard";
import OwnershipDocument from "@/components/TailwindComponents/OwnershipDocument";
import HighRiskIndustryCard from "@/components/TailwindComponents/HighRiskIndustryCard";
import IncorporationDocument from "@/components/TailwindComponents/IncorporationDocument";
import KYCProofOfAddressCard from "@/components/TailwindComponents/KYCProofOfAddressCard";
import KYBProofOfAddressCard from "@/components/TailwindComponents/KYBProofOfAddressCard";
import AdverseMediaHit from "@/components/TailwindComponents/AdverseMediaHit";
import BusinessDueDiligenceCard from "@/components/TailwindComponents/BusinessDueDiligenceCheckCard";
import ProhibitedHighRiskCountryCard from "@/components/TailwindComponents/ProhibitedHighRiskCountryCard";
import SanctionsWatchlistCard from "@/components/TailwindComponents/SanctionsWatchlistCard";
import OpenCorporatesCard from "@/components/TailwindComponents/OpenCorporatesCard";
import MCCCard from "@/components/TailwindComponents/MCCCard";
import ResultAlerts from "@/components/TailwindComponents/ResultAlerts";
import ChatContent from "../../Chat/Content";
import TINCheckCard from "@/components/TailwindComponents/TINCheckCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import WarningIcon from "@mui/icons-material/WarningRounded";
import CancelRounded from "@mui/icons-material/CancelRounded";
import Icon from "@mui/material/Icon";
import Accordion from "../Accordion";
import { ArrowUpward, ArrowDownward, ErrorOutline, ArrowRightAlt, InfoRounded } from "@mui/icons-material";
import { ChecksFeedback } from "@/components/TailwindComponents/ChecksFeedback";
import CheckStatusMessage from "@/components/TailwindComponents/CheckStatusMessage";
import FakeProgress from "fake-progress";
import Progress from "@/components/ui/progress";
import OC_icon from "@/assets/OC_icon.png";
import OpenCorporatesOfficersCard from "@/components/TailwindComponents/OpenCorporatesOfficersCard";
import AddressVerificationCard from "@/components/TailwindComponents/AddressVerificationCard";
import OpenCorporatesAssociatedEntitiesCard from "@/components/TailwindComponents/OpenCorporatesAssociatedEntitiesCard";
import OpenSourceBusinessDueDiligenceCard from "@/components/TailwindComponents/OpenSourceBusinessDueDiligenceCard";
import UDAPDocumentCard from "@/components/TailwindComponents/UDAPDocumentCard";
import WebsiteResearchCard from "@/components/TailwindComponents/WebsiteResearchCard";
import WebsitePolicyCard, { PolicyType } from "@/components/TailwindComponents/WebsitePolicyCard";
import EINCard from "@/components/TailwindComponents/EINCard";
import SourceOfFundsCard from "@/components/TailwindComponents/SourceOfFundsCard";
import AdverseMediaPotentialHitV2 from "@/components/TailwindComponents/AdverseMediaPotentialHitV2";
import BusinessBasicProfile from "@/components/TailwindComponents/BusinessBasicProfile";
import WebPresenceList from "@/components/TailwindComponents/WebPresence";
import AddressesFindings from "@/components/TailwindComponents/AddressesFindings";
import PoliciesFindings from "@/components/TailwindComponents/PoliciesFindings";
import BusinessOwnersFindings from "@/components/TailwindComponents/BusinessOwnersFindings";
import HighRiskCountryFindings from "@/components/TailwindComponents/HighRiskCountryFindings";
import HighRiskIndustryFindings from "@/components/TailwindComponents/HighRiskIndustryFindings";
import TabularAdverseMedia from "@/components/TailwindComponents/TabularAdverseMedia";
import PEPV2Container from "@/components/TailwindComponents/PEPV2Container";
import TabularPEP from "@/components/TailwindComponents/TabularPEP";
import AdverseMediaV2 from "@/components/TailwindComponents/AdverseMediaV2";
import CannabisLicenseCard from "@/components/TailwindComponents/CannabisLicenseCard";
import SelfAttestedAddressVerificationCard from "@/components/TailwindComponents/SelfAttestedAddressVerificationCard";
import AdverseMediaV3 from "@/components/TailwindComponents/AdverseMediaV3";
import PEPCardV3 from "@/components/TailwindComponents/PEPCardV3";

const iconsMap: { [k: string]: string } = {
  "KYC Government ID Verification Tool": "badge",
  "Government ID Verification": "badge",
  "KYB TIN Format Check Tool": "pin",
  "KYB TIN Check Tool": "pin",
  "TIN Check": "pin",
  "TIN Format Check": "pin",
  "EIN Verification Tool": "pin",
  "KYB High Risk Country Screening Tool": "public_off",
  "High Risk Country Check": "public_off",
  "High-Risk Countries": "public_off",
  "KYB High Risk Industry Screening Tool": "domain_disabled",
  "KYB Self Attested Address Verification Tool": "markunread_mailbox",
  "High Risk Industry Check": "domain_disabled",
  "High-Risk Industries": "domain_disabled",
  "Address Verification": "markunread_mailbox",
  "Proof of Address Verification Tool": "markunread_mailbox",
  "Proof of Address Extraction Tool": "markunread_mailbox",
  "Proof of Address Check for Business": "markunread_mailbox",
  "Incorporation Document Check": "description",
  "Business Registration Extraction Tool": "description",
  "Business Registration Verification Tool": "description",
  "Business Ownership Document Check": "description",
  "Business Ownership Verification tool": "description",
  "Business Ownership Extraction tool": "description",
  "KYB Sanctions List Screening Tool": "visibility",
  "Adverse Media Check": "receipt_long",
  "KYB Adverse Media Screening Tool": "receipt_long",
  "KYC Adverse Media Screening Tool": "receipt_long",
  "Adverse Media Profile Check": "receipt_long",
  "Adverse Media Event Check": "receipt_long",
  "Adverse Media": "receipt_long",
  "PEP Screening Check": "tour",
  "KYC PEP Screening Tool": "tour",
  "Politically Exposed Person Check": "tour",
  "UBO Entity Sanctions Watchlist Check": "visibility",
  "Sanctions Watchlist Check": "visibility",
  "Sanctions Watchlist": "visibility",
  "Individual Watchlist Check": "visibility",
  "KYC Sanctions Watchlist Screening Tool": "visibility",
  "Open Source Business Due Diligence Check": "business",
  "KYB Open Source Business Due Diligence Check Tool": "business",
  "Perform Web Research on the Business": "screen_search_desktop",
  "KYB Web Research Tool": "screen_search_desktop",
  "KYB MCC Check Tool": "factory",
  "MCC Code Check": "factory",
  "MCC Code": "factory",
  "UDAP Check": "policy",
  "Source of Funds Document Verification Tool": "request_quote",
  "Basic Profile Check": "business",
  "Business Basic Profile": "business",
  Addresses: "location_on",
  "Addresses Check": "location_on",
  "Policies Check": "policy",
  Policies: "policy",
  "Business Owners Check": "person",
  "Web Presence Check": "language",
  "Online Presence": "language",
  "Self Attested Address Verification": "markunread_mailbox",
};

const CheckContainer = ({
  caseId,
  agentRun,
  apiKey,
  commandResult,
  auditLogMessages,
  commandInstanceId,
  endpointUrl,
  feedbackData,
  sendFeedbackHandler,
  messages,
  title,
  isLegacyJob,
  setSelectedSidePanelTabValue,
  compact,
  showTabular = true,
  selectedGenders = [],
  selectedLocations = [],
  expanded = true,
  handleDocumentValidationClick,
}: any) => {
  const [isAuditLogVisible, setIsAuditLogVisible] = useState(true);
  const [activeFeedbackId, setActiveFeedbackId] = useState<string | null>(null); //agent_instance_id+command_instance_id
  const [comment, setComment] = useState<string>("");
  const [commentError, setCommentError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (commandResult) return;

    const p = new FakeProgress({
      timeConstant: 10000,
      autoStart: true,
    });

    const interval = setInterval(() => {
      setProgress(p.progress);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const renderAccordionTitle = (messages: StatusMessage[]) => {
    const commandResultMessage = messages.find((m) => m.event === "command_results");
    const errorMessage = messages.some((m) => m.event === "error");
    const didPass = ["true", "pass", "passed", "yes"].includes(commandResult?.passed?.toString().toLowerCase());

    const iconKey = iconsMap[title];
    const alerts = commandResultMessage?.content?.output?.alerts
      ? Object.entries(commandResultMessage.content.output.alerts)
      : [];

    return compact ? null : (
      <div className="h-full w-full flex flex-col gap-x-2 gap-y-4">
        <div className="flex items-center gap-2 lg:gap-0 lg:flex-row justify-between">
          <div className="flex gap-x-2 lg:items-center grow line-clamp-1">
            {title?.includes("Open Corporates") ? (
              <img src={OC_icon} width={20} height={20} className="h-5 w-5" />
            ) : (
              <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
                {iconKey}
              </Icon>
            )}
            <span>{title}</span>
          </div>

          <div className="flex flex-col lg:flex-row gap-1 items-center">
            {!commandResult && !["error", "failed", "complete"].includes(agentRun.status) && firstMessage && (
              <Progress className="w-[5rem] h-[0.5rem]" value={progress * 100} />
            )}
            {errorMessage && <ErrorOutline sx={{ fontSize: "1.25rem" }} className="text-red-500" />}
            {alerts.length > 0 && <WarningIcon sx={{ fontSize: "1.25rem" }} className="text-yellow-500" />}
            {commandResult.passed !== null ? (
              didPass ? (
                <CheckCircleIcon sx={{ fontSize: "1.25rem" }} className="text-green-600" />
              ) : (
                <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderAccordionMain = (messages: StatusMessage[]) => {
    const commandResult = messages.find((m) => m.event === "command_results");
    const lastStatusMessage = messages.findLast((message) => message.event === "status");
    const errorMessage = messages.find((m) => m.event === "error");

    return (
      <>
        {!commandResult && !errorMessage && auditLogMessages.length > 0 ? (
          <ChatContent
            key={commandInstanceId}
            statusMessages={auditLogMessages}
            isParchaUser={false}
            jobStatus={agentRun.status}
            feedbackData={feedbackData}
            caseId={caseId}
            endpointUrl={endpointUrl}
          />
        ) : null}
        {errorMessage ? (
          <div className="ignore-in-pdf flex flex-col gap-4 mt-2">
            <div className="border-2 border-red-500 rounded-md w-full p-4 text-red-700 text-xs">
              <span className="font-semibold">Error: </span>
              <p className="overflow-hidden text-ellipsis nowrap">{errorMessage.content.output.error_display}</p>
            </div>
            <div className="place-self-end">
              <button
                type="button"
                className="inline-flex items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-1.5 font-medium text-xs text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                onClick={() => onContactSupportClickHandler(errorMessage.content.output)}
              >
                Contact Support
              </button>
            </div>
          </div>
        ) : lastStatusMessage && !commandResult && agentRun.status === "in progress" ? (
          <CheckStatusMessage messageContents={{ status: lastStatusMessage.content.status || "" }} />
        ) : null}
      </>
    );
  };

  const renderPEP = (verifiedPepHits, input, agentKey, commandId, agentInstanceId, commandInstanceId) => {
    return (
      <PEPCardV3
        verifiedPepHits={verifiedPepHits}
        input={input}
        commandId={commandId}
        agentInstanceId={agentInstanceId}
        commandInstanceId={commandInstanceId}
        agentKey={agentKey}
        endpointUrl={endpointUrl}
        jobId={agentRun.id}
      />
    );
  };

  const renderAdverseMedia = (
    verifiedAdverseMediaHits,
    input,
    agentKey,
    commandId,
    agentInstanceId,
    commandInstanceId,
  ) => {
    return (
      <AdverseMediaV3
        verifiedAdverseMediaHits={verifiedAdverseMediaHits}
        input={input}
        commandId={commandId}
        agentInstanceId={agentInstanceId}
        commandInstanceId={commandInstanceId}
        agentKey={agentKey}
        endpointUrl={endpointUrl}
        jobId={agentRun.id}
      />
    );
  };

  const renderAccordionContent = (messages: StatusMessage[]) => {
    const selfAttestedDataMessage = agentRun.status_messages?.find((message) => {
      const commandResult = message.content as CommandResult;
      const output = commandResult?.output as OutputSchema;
      const payload = output?.payload;
      const type = payload?.type;

      return message.event === "api_results" && type === "SelfAttestedData";
    });

    const commandResultMessage = messages.find((message) => message.event === "command_results");

    const alerts = commandResultMessage?.content?.output?.alerts
      ? Object.entries(commandResultMessage.content.output.alerts)
      : [];

    if (!commandResultMessage) return null;

    const didPass = ["true", "pass", "passed", "yes"].includes(commandResult?.passed?.toString().toLowerCase());
    const output = commandResult.check_result;
    const payload = commandResult.check_result;
    const level = didPass ? "success" : "failure";
    const feedbackKey = `${commandResultMessage.content.agent_instance_id}${commandResultMessage.content.command_instance_id}`;

    const getIncorporationDocuments = () => {
      // First, try the old way with payload.verified_documents
      if (payload?.verified_documents?.some((entry) => entry.document)) {
        return payload.verified_documents.map((entry) => ({
          fileName: entry.document.file_name,
          url: `${
            window.location.protocol
          }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`,
          summary: entry.summary,
        }));
      }

      if (payload?.valid_documents?.some((entry) => entry.document)) {
        return payload.valid_documents.map((entry) => ({
          fileName: entry.document.file_name,
          url: `${
            window.location.protocol
          }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`,
          summary: entry.summary,
        }));
      }

      // If the old way isn't available, try the new way with commandResult.verified_data.documented_incorporation_information
      if (commandResult.verified_data?.documented_incorporation_information?.some((record) => record.document)) {
        return commandResult.verified_data.documented_incorporation_information.map((entry) => ({
          fileName: entry.document.file_name,
          url: `${
            window.location.protocol
          }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`,
          summary: entry.summary,
          fraudVerificationData: entry.fraud_verification_data,
        }));
      }

      // If neither is available, return an empty array
      return [];
    };

    return (
      <div key={commandResultMessage.timestamp} className="flex flex-col gap-4">
        {!compact && (
          <div className="mt-2">
            <Alert level={didPass ? "success" : "failure"} message={commandResultMessage?.content?.output?.answer} />
          </div>
        )}

        {alerts.length > 0 && <ResultAlerts alerts={alerts} />}
        {payload?.type === "AdverseMediaCheckResult" && payload?.verified_adverse_media_hits
          ? payload.verified_adverse_media_hits.map((mediaHit: AdverseMediaHitType) => {
              return (
                <div key={index} className="flex flex-col gap-5 mt-5">
                  <SanctionsWatchlistCard
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists[0]}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                </div>
              );
            })
          : null}
        {payload?.verified_sanctions_hits
          ? payload.verified_sanctions_hits.map((sanctionHit: SanctionHit, index: number) => {
              return (
                <div key={index} className="flex flex-col gap-5 mt-5">
                  <SanctionsWatchlistCard
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists[0]}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                </div>
              );
            })
          : null}
        {payload?.type === "PEPScreeningCheckResult" && payload?.verified_pep_hits?.length
          ? payload.verified_pep_hits.map((sanctionHit: SanctionHit, index: number) => {
              return (
                <div key={index} className="flex flex-col gap-5 mt-5">
                  <SanctionsWatchlistCard
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists[0]}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                </div>
              );
            })
          : null}

        {payload?.type === "OpenCorporatesBusinessCheckResult" &&
          payload.open_corporates_hits?.map((openCorporateResult: OpencorporatesData) => {
            return (
              <OpenCorporatesCard
                key={openCorporateResult.name + openCorporateResult.source}
                source={openCorporateResult.source}
                name={openCorporateResult.name}
                inactive={openCorporateResult.inactive}
                address={{
                  street1: openCorporateResult.address,
                  street2: null,
                  city: null,
                  state: null,
                  countryCode: null,
                  postalCode: null,
                }}
                incorporationDate={openCorporateResult.incorporation_date}
                openCorporatesUrl={openCorporateResult.opencorporates_url}
                registryUrl={openCorporateResult.registry_url}
                level={level}
              />
            );
          })}
        {payload?.type === "OpenCorporatesOfficersCheckResult" && (
          <div className="flex flex-col gap-y-2">
            {payload.verified_business_owners?.map(
              (owner: {
                name: string;
                open_corporates_business: {
                  business_name: string;
                  open_corporates_url: string;
                  positions_held_by_business_owner: string[];
                }[];
              }) => (
                <OpenCorporatesOfficersCard
                  key={owner.name}
                  name={owner.name}
                  companies={
                    owner.open_corporates_business?.map((business) => ({
                      name: business.business_name,
                      url: business.open_corporates_url,
                      position: business.positions_held_by_business_owner,
                    })) || []
                  }
                  level={level}
                  tag="Matched"
                  tagLevel="success"
                />
              ),
            )}
            {payload.not_attested_business_owners?.map((notAttestedOwner: string) => {
              return (
                <OpenCorporatesOfficersCard
                  key={notAttestedOwner}
                  name={notAttestedOwner}
                  companies={[]}
                  level="failure"
                  tag="Not Listed"
                  tagLevel="failure"
                />
              );
            })}

            {payload.unverified_business_owners?.map((unverifiedOwner: string) => {
              return (
                <OpenCorporatesOfficersCard
                  key={unverifiedOwner}
                  name={unverifiedOwner}
                  companies={[]}
                  level={"failure"}
                  tag="Not Found"
                  tagLevel="failure"
                />
              );
            })}
          </div>
        )}
        {payload?.type === "OpenCorporatesOfficersAssociationsCheckResult" &&
          payload.business_potential_associations?.length > 0 && (
            <div className="flex flex-col gap-y-4">
              {selfAttestedDataMessage ? (
                <div className="p-4 text-xs bg-blue-100 text-slate-700 rounded flex gap-x-1 items-center">
                  <InfoRounded sx={{ fontSize: "1rem" }} className="text-slate-700" />
                  <span className="font-medium">
                    {`The following businesses are potentially associated with officers of ${
                      selfAttestedDataMessage.content.output.payload.business_name ||
                      selfAttestedDataMessage.content.output.payload.registered_business_name
                    }.`}
                  </span>
                </div>
              ) : null}
              <div className="flex flex-col gap-y-2">
                {payload.business_potential_associations?.map(
                  (business: {
                    business_name: string;
                    opencorporates_url: string;
                    associated_business_owners: {
                      name: string;
                      open_corporates_url: string | null;
                      positions_held_by_business_owner: string[];
                    }[];
                  }) => (
                    <OpenCorporatesAssociatedEntitiesCard
                      key={business.business_name}
                      name={business.business_name}
                      url={business.opencorporates_url}
                      officers={business.associated_business_owners?.map((owner) => ({
                        name: owner.name,
                        position: owner.position,
                      }))}
                      level={"normal"}
                    />
                  ),
                )}
              </div>
            </div>
          )}
        {payload?.type === "SelfAttestedAddressCheckResult" && (
          <div className="mt-4">
            <AddressVerificationCard
              level={level}
              address={{
                street1: commandResult.input_data.address_of_operation.street_1,
                street2: commandResult.input_data.address_of_operation.street_2,
                city: commandResult.input_data.address_of_operation.city,
                state: commandResult.input_data.address_of_operation.state,
                postalCode: commandResult.input_data.address_of_operation.postal_code,
                countryCode: commandResult.input_data.address_of_operation.country_code,
              }}
              addressType={
                payload.operating_address_is_business
                  ? "Business"
                  : payload.operating_address_is_residential
                    ? "Residential"
                    : payload.operating_address_is_pobox
                      ? "PO Box"
                      : "Address not valid"
              }
            />
          </div>
        )}
        {payload?.type === "OpenSourceBusinessDueDiligenceCheckResult" ? (
          payload.verified_business_name ? (
            <div className="flex justify-center items-center flex-col">
              <BusinessDueDiligenceCard
                level={level}
                name={payload.verified_business_name}
                address={
                  payload.verified_business_address
                    ? {
                        street1: payload.verified_business_address.street_1,
                        street2: payload.verified_business_address.street_2,
                        city: payload.verified_business_address.city,
                        state: payload.verified_business_address.state,
                        postalCode: payload.verified_business_address.postal_code,
                        countryCode: payload.verified_business_address.country_code,
                      }
                    : {
                        street1: "",
                        street2: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        countryCode: "",
                      }
                }
                description={payload.verified_business_description}
              />
            </div>
          ) : (
            <OpenSourceBusinessDueDiligenceCard
              level={level}
              name={commandResult.input_data.business_name}
              description={commandResult.input_data.business_description}
              businessDescriptionMatch={payload.business_description_is_exact_match_validated}
              websiteUrl={commandResult.input_data.business_website}
              businessNameMatch={
                payload.business_name_is_exact_match_validated
                  ? "exact"
                  : payload.business_name_is_soft_match_validated
                    ? "soft"
                    : "none"
              }
              businessWebsiteMatch={payload.business_website_is_exact_match_validated}
              incorporationAddress={{
                street1: commandResult.input_data.address_of_incorporation.street_1,
                street2: commandResult.input_data.address_of_incorporation.street_2,
                city: commandResult.input_data.address_of_incorporation.city,
                state: commandResult.input_data.address_of_incorporation.state,
                postalCode: commandResult.input_data.address_of_incorporation.postal_code,
                countryCode: commandResult.input_data.address_of_incorporation.country_code,
              }}
              operationAddress={{
                street1: commandResult.input_data.address_of_operation.street_1,
                street2: commandResult.input_data.address_of_operation.street_2,
                city: commandResult.input_data.address_of_operation.city,
                state: commandResult.input_data.address_of_operation.state,
                postalCode: commandResult.input_data.address_of_operation.postal_code,
                countryCode: commandResult.input_data.address_of_operation.country_code,
              }}
              addressOfOperationMatch={
                payload.address_of_operation_is_exact_match_validated
                  ? "exact"
                  : payload.address_of_operation_is_soft_match_validated
                    ? "soft"
                    : "none"
              }
              addressOfIncorporationMatch={
                payload.address_of_incorporation_is_exact_match_validated
                  ? "exact"
                  : payload.address_of_incorporation_is_soft_match_validated
                    ? "soft"
                    : "none"
              }
              businessOwnerMatches={[]}
            />
          )
        ) : null}

        {payload?.type === "OpenSourceBusinessDueDiligenceCheckResultV2" ? (
          payload.verified_business_name ? (
            <div className="flex justify-center items-center flex-col">
              <BusinessDueDiligenceCard
                level={level}
                name={payload.verified_business_name}
                address={
                  payload.verified_business_address
                    ? {
                        street1: payload.verified_business_address.street_1,
                        street2: payload.verified_business_address.street_2,
                        city: payload.verified_business_address.city,
                        state: payload.verified_business_address.state,
                        postalCode: payload.verified_business_address.postal_code,
                        countryCode: payload.verified_business_address.country_code,
                      }
                    : {
                        street1: "",
                        street2: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        countryCode: "",
                      }
                }
                description={payload.verified_business_description}
              />
            </div>
          ) : (
            <OpenSourceBusinessDueDiligenceCard
              level={level}
              name={payload.business_name_match?.name || commandResult.input_data.business_name}
              description={payload.business_description_match?.description}
              businessDescriptionMatchExplanation={payload.business_description_match?.explanation}
              businessDescriptionMatch={
                payload.business_description_match?.exact_match
                  ? "exact"
                  : payload.business_description_match?.partial_match
                    ? "soft"
                    : "none"
              }
              websiteUrl={payload.business_website_match?.website}
              businessNameMatchExplanation={payload.business_name_match?.explanation}
              businessNameMatch={
                payload.business_name_match?.exact_match
                  ? "exact"
                  : payload.business_name_match?.partial_match
                    ? "soft"
                    : "none"
              }
              businessWebsiteMatchExplanation={payload.business_website_match?.explanation}
              businessWebsiteMatch={
                payload.business_website_match?.exact_match
                  ? "exact"
                  : payload.business_website_match?.partial_match
                    ? "soft"
                    : "none"
              }
              incorporationAddress={{
                street1:
                  payload.business_incorporation_address_match?.address?.street_1 ||
                  commandResult.input_data.address_of_incorporation.street_1,
                street2:
                  payload.business_incorporation_address_match?.address?.street_2 ||
                  commandResult.input_data.address_of_incorporation.street_2,
                city:
                  payload.business_incorporation_address_match?.address?.city ||
                  commandResult.input_data.address_of_incorporation.city,
                state:
                  payload.business_incorporation_address_match?.address?.state ||
                  commandResult.input_data.address_of_incorporation.state,
                postalCode:
                  payload.business_incorporation_address_match?.address?.postal_code ||
                  commandResult.input_data.address_of_incorporation.postal_code,
                countryCode:
                  payload.business_incorporation_address_match?.address?.country_code ||
                  commandResult.input_data.address_of_incorporation.country_code,
              }}
              operationAddress={{
                street1:
                  payload.business_operating_address_match?.address?.street_1 ||
                  commandResult.input_data.address_of_operation.street_1,
                street2:
                  payload.business_operating_address_match?.address?.street_2 ||
                  commandResult.input_data.address_of_operation.street_2,
                city:
                  payload.business_operating_address_match?.address?.city ||
                  commandResult.input_data.address_of_operation.city,
                state:
                  payload.business_operating_address_match?.address?.state ||
                  commandResult.input_data.address_of_operation.state,
                postalCode:
                  payload.business_operating_address_match?.address?.postal_code ||
                  commandResult.input_data.address_of_operation.postal_code,
                countryCode:
                  payload.business_operating_address_match?.address?.country_code ||
                  commandResult.input_data.address_of_operation.country_code,
              }}
              businessOperatingAddressMatchExplanation={payload.business_operating_address_match?.explanation}
              addressOfOperationMatch={
                payload.business_operating_address_match?.exact_match
                  ? "exact"
                  : payload.business_operating_address_match?.partial_match
                    ? "soft"
                    : "none"
              }
              addressOfIncorporationMatch={
                payload.address_of_incorporation_match?.exact_match
                  ? "exact"
                  : payload.address_of_incorporation_match?.partial_match
                    ? "soft"
                    : "none"
              }
              businessIncorporationAddressMatchExplanation={payload.business_incorporation_address_match?.explanation}
              businessOwnerMatches={
                payload.business_owner_matches?.map(
                  (match: { name: string; exact_match: boolean; partial_match: boolean }) => ({
                    name: match.name,
                    match: match.exact_match ? "exact" : match.partial_match ? "soft" : "none",
                  }),
                ) || []
              }
              sources={payload.sources || []}
            />
          )
        ) : null}

        {payload?.type === "ProofOfAddressCheckResult" ? (
          <div className="flex justify-center">
            <KYBProofOfAddressCard
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              businessName={payload.verified_name}
              level={level}
              proofOfAddressDocuments={(
                commandResult.verified_data?.valid_documents || commandResult.verified_data?.proof_of_address_documents
              )
                ?.filter((entry) => entry.document)
                .map((data) => ({
                  fileName: data.document.file_name,
                  url: `${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(data.document.url)}`,
                  documentType: data.document_type,
                  fraudVerificationData: data.fraud_verification_data,
                }))}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "KYCProofOfAddressCheckResult" ? (
          <div className="flex justify-center">
            <KYCProofOfAddressCard
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              name={payload.verified_name}
              level={level}
              title={null}
              proofOfAddressDocuments={(
                commandResult.verified_data?.valid_documents || commandResult.verified_data?.proof_of_address_documents
              )
                ?.filter((entry) => entry.document)
                .map((data) => ({
                  fileName: data.document.file_name,
                  url: `${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(data.document.url)}`,
                  documentType: data.document_type,
                  fraudVerificationData: data.fraud_verification_data,
                }))}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "KYBIncorporationDocumentVerificationResult" ? (
          <div className="flex justify-center">
            <IncorporationDocument
              level={level}
              address={
                payload.verified_business_address
                  ? {
                      street1: payload.verified_business_address.street_1,
                      street2: payload.verified_business_address.street_2,
                      city: payload.verified_business_address.city,
                      state: payload.verified_business_address.state,
                      postalCode: payload.verified_business_address.postal_code,
                      countryCode: payload.verified_business_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              businessActivity={payload.verified_business_activity}
              companyName={payload.verified_business_name}
              companyNumber={
                payload.verified_business_registration_number || commandResult.input_data.business_registration_number
              }
              incorporationDate={payload.verified_incorporation_date}
              incorporationDocuments={getIncorporationDocuments()}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "KYBBusinessOwnershipVerificationResult" ? (
          <div className="flex justify-center">
            <OwnershipDocument
              level={level}
              businessOwners={
                payload.verified_business_owners?.map(
                  (owner: { full_name: string; percentage: number | null; shares: number | null }) => ({
                    fullName: owner.full_name,
                    percentage: owner.percentage,
                    shares: owner.shares,
                  }),
                ) || []
              }
              ownershipDocuments={(
                commandResult.verified_data?.valid_documents || commandResult.verified_data?.documented_ownership
              )
                ?.filter((entry) => entry.document)
                .map(
                  (record: {
                    document: { file_name: string; url: string };

                    fraud_verification_data?: FraudVerificationData;
                    summary?: string;
                  }) => ({
                    fileName: record.document.file_name,
                    url: `${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(
                      record.document.url,
                    )}`,
                    summary: record.summary,
                    fraudVerificationData: record.fraud_verification_data,
                  }),
                )}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "GovernmentIDCheckResult" ? (
          <div className="flex justify-center">
            <GovernmentIDCard
              name={`${payload.verified_first_name ?? ""}${
                payload.verified_middle_names ? ` ${payload.verified_middle_names}` : ""
              }${payload.verified_last_name ? ` ${payload.verified_last_name}` : ""}
                    `}
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              countryOfResidence={payload.verified_country_of_residence}
              dateOfBirth={payload.verified_date_of_birth}
              idType={payload.government_id_type}
              level={level}
              idImageURL={commandResult.verified_data.id_front_image_url}
              evidenceMessage={output.answer}
            />
          </div>
        ) : null}
        {payload?.type === "TINFormatCheckResult" ? (
          <TINCheckCard
            businessName={commandResult.input_data.business_name}
            tin={commandResult.input_data.tin_number}
            level={level}
          />
        ) : null}
        {payload?.type === "HighRiskCountryCheckResult" &&
        payload?.verified_country &&
        payload?.verified_country !== "" ? (
          <ProhibitedHighRiskCountryCard
            level={level}
            countries={[{ name: payload.verified_country, countryCode: payload.verified_country }]}
            evidenceMessage={output.answer}
          />
        ) : null}
        {payload?.type === "IndustryActivityCheckResult" ? (
          <HighRiskIndustryCard
            industry={payload.verified_industry}
            businessActivity={payload.verified_business_activity}
            level={level}
            evidenceMessage={output.explanation}
          />
        ) : null}

        {payload?.type === "UDAPCheckResults" ? (
          <div className="flex flex-col gap-5">
            {payload.processed_documents.map((document, index) => {
              return (
                <UDAPDocumentCard
                  key={index}
                  level="failure"
                  pageCount={document.UDAP_results.length}
                  documentTitle={document?.document?.file_name}
                  documentUrl={document?.document?.url}
                  matchingEntries={document.UDAP_results.map((udapResult, index) => {
                    const violations = udapResult.violations.map((violation) => {
                      return {
                        contextText: violation.context_text,
                        triggeredKeyword: violation.triggered_keyword,
                        reason: violation.reason,
                        pageNumber: index + 1,
                        highlightArea: {
                          pageIndex: index,
                          height: (violation.bounding_box?.y_max - violation.bounding_box?.y_min) * 100 + 0.5,
                          width: (violation.bounding_box?.x_max - violation.bounding_box?.x_min) * 100 + 0.75,
                          top: violation.bounding_box?.y_min * 100 - 0.27125,
                          left: violation.bounding_box?.x_min * 100 - 0.4,
                          triggeredKeyword: violation.triggered_keyword,
                          reason: violation.reason,
                          contextText: violation.context_text,
                        },
                      };
                    });
                    return violations;
                  }).flat()}
                />
              );
            })}
          </div>
        ) : null}

        {payload?.type === "MCCCodeCheckResult" ? (
          <MCCCard
            businessDescription={payload.mcc_code_description}
            businessName={commandResult.input_data?.business_name}
            level={didPass ? "success" : "failure"}
            mccCode={payload.mcc_code}
            productsSold={payload.products_sold}
            website={commandResult.input_data?.website ?? null}
          />
        ) : null}

        {payload?.type === "WebResearchDataCheckResult" ? (
          <div className="flex flex-col gap-y-5">
            {payload.self_attested_webpages?.map((webpage, index) => {
              return (
                <WebsiteResearchCard
                  key={index}
                  domain={webpage.webpage_metadata?.domain}
                  isRelatedUrl={webpage.is_relevant}
                  isValidUrl={webpage.is_valid_url}
                  screenshotUrl={webpage.screenshot_url}
                  title={webpage.webpage_title}
                  url={webpage.webpage_url}
                  websiteType={webpage.webpage_metadata?.webpage_type}
                  websiteSummary={webpage.webpage_metadata?.summary}
                />
              );
            })}
            {payload.website_checks?.length > 0 && (
              <div className="flex flex-col gap-y-5 text-xs">
                <span className="font-semibold">Policies</span>
                {payload.website_checks.map((websiteCheck: PolicyType, index: number) => {
                  return <WebsitePolicyCard {...websiteCheck} key={index} />;
                })}
              </div>
            )}
          </div>
        ) : null}

        {payload?.type === "KYBEINDocumentVerificationResult" ? (
          <div className="flex justify-center">
            <EINCard
              addressOfOperation={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: null,
                      street2: null,
                      city: null,
                      state: null,
                      postalCode: null,
                      countryCode: null,
                    }
              }
              businessName={payload.verified_business_name}
              documents={payload.valid_documents || payload.invalid_documents}
              level={level}
              einNumber={payload.verified_ein}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}

        {payload?.type === "CannabisLicenseVerificationResult" ? (
          <div className="flex justify-center">
            <CannabisLicenseCard
              level={level}
              verifiedBusinessName={payload.verified_business_name}
              verifiedLicenseNumber={payload.verified_license_number}
              documentDate={payload.document_date}
              validDocuments={payload.valid_documents?.map((entry) => ({
                fileName: entry.document.file_name,
                url: `${
                  window.location.protocol
                }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`,
                summary: entry.summary,
                expirationDate: entry.expiration_date,
                licenseNumber: entry.license_number,
                visualInspection: entry.inspection_details,
              }))}
              invalidDocuments={payload.invalid_documents?.map((entry) => ({
                fileName: entry.document.file_name,
                url: `${
                  window.location.protocol
                }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`,
                summary: entry.summary,
                expirationDate: entry.expiration_date,
                licenseNumber: entry.license_number,
                visualInspection: entry.inspection_details,
              }))}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}

        {payload?.type === "KYBSourceOfFundsDocumentVerificationResult" ? (
          <div className="flex justify-center">
            <SourceOfFundsCard
              level={level}
              amount={payload.verified_amount}
              businessName={payload.verified_business_name}
              documents={payload.verified_source_of_funds_documents}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}

        {payload?.type === "KYCAdverseMediaScreeningCheckResultV2" && commandResult?.input_data
          ? renderAdverseMedia(
              payload.verified_adverse_media_hits || payload.perpetrator_profiles,
              commandResult.input_data,
              commandResultMessage.content.agent_key,
              commandResultMessage.content.command_id,
              commandResultMessage.content.agent_instance_id,
              commandResultMessage.content.command_instance_id,
            )
          : null}

        {payload?.type === "KYCAdverseMediaEventScreeningCheckData" && payload?.potential_matches?.length > 0 ? (
          <div className="mt-4 flex flex-col gap-y-3">
            {payload.potential_matches.map((potentialMatch: any, index: number) => {
              return <AdverseMediaPotentialHitV2 key={index} {...potentialMatch} />;
            })}
          </div>
        ) : null}

        {commandResultMessage?.content?.output?.payload?.type === "PEPScreeningCheckResultV2" &&
        commandResultMessage?.content?.output?.payload?.verified_pep_hits?.length > 0 &&
        commandResult?.input_data
          ? renderPEP(
              commandResultMessage.content.output.payload.verified_pep_hits,
              commandResult?.input_data,
              commandResultMessage.content.agent_key,
              commandResultMessage.content.command_id,
              commandResultMessage.content.agent_instance_id,
              commandResultMessage.content.command_instance_id,
            )
          : null}

        {payload?.type === "BasicBusinessProfileCheckResult" && (
          <BusinessBasicProfile
            checkResult={{
              verified_data: commandResult.verified_data,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "WebPresenceCheckResult" && (
          <WebPresenceList
            checkResult={{
              verified_data: commandResult.verified_data,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "AddressesCheckResult" && (
          <AddressesFindings
            checkResult={{
              verified_data: commandResult.verified_data,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "PoliciesCheckResult" && (
          <PoliciesFindings
            checkResult={{
              check_result: {
                policies_found: payload.policies_found,
              },
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            endpointUrl={endpointUrl}
            apiKey={apiKey}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "BusinessOwnersCheckResult" && (
          <BusinessOwnersFindings
            checkResult={{
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
              check_result: payload,
            }}
            endpointUrl={endpointUrl}
            apiKey={apiKey}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "HighRiskCountryCheckResultV2" && (
          <HighRiskCountryFindings
            checkResult={{
              check_result: payload,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
              passed: true,
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "IndustryActivityCheckResultV2" && (
          <HighRiskIndustryFindings
            checkResult={{
              check_result: payload,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "BusinessAddressVerificationResult" && (
          <SelfAttestedAddressVerificationCard
            address={payload.address}
            business_name={payload.found_entity_name}
            address_type={payload.address_type}
            found_website={payload.found_entity_website}
            passed={commandResult.passed}
            explanation={commandResult.answer}
          />
        )}

        <div className="flex flex-col gap-y-5">
          <div className="w-full flex justify-between">
            <div className="flex gap-x-3 items-center">
              <ChecksFeedback
                key={feedbackKey}
                score={
                  activeFeedbackId === feedbackKey
                    ? false
                    : feedbackData?.find((feedback) => feedback.key === feedbackKey)?.score
                }
                onThumbsDownClick={() => onThumbsDownClickHandler(feedbackKey)}
                onThumbsUpClick={() => onThumbsUpClickHandler(commandResultMessage)}
              />
              {feedbackData?.find(
                (feedback) => feedback.key === feedbackKey && typeof feedback.score === "boolean" && !feedback.score,
              ) && (
                <button
                  className="text-sm text-brand-purple hover:underline"
                  type="button"
                  onClick={() => {
                    if (activeFeedbackId === feedbackKey) {
                      setActiveFeedbackId(null);
                      return;
                    }

                    setActiveFeedbackId(feedbackKey);
                    setComment(feedbackData.find((feedback) => feedback.key === feedbackKey)?.comment);
                  }}
                >
                  {activeFeedbackId !== feedbackKey ? "View Feedback" : "Hide Feedback"}
                </button>
              )}
            </div>
            <div className="ignore-in-pdf">
              {isLegacyJob ? (
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-1.5 text-xs font-medium text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                  onClick={() => handleViewInAuditLogClick(commandResultMessage)}
                >
                  View In Audit Log
                  <ArrowRightAlt className="h-4 w-4" aria-hidden="true" />
                </button>
              ) : (
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-3 text-xs font-medium text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                  onClick={() => setIsAuditLogVisible((prev) => !prev)}
                >
                  {isAuditLogVisible ? "Hide Audit Log" : "View Audit Log"}
                  {isAuditLogVisible ? (
                    <ArrowUpward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                  ) : (
                    <ArrowDownward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                  )}
                </button>
              )}
            </div>
          </div>
          {activeFeedbackId === feedbackKey && (
            <form onSubmit={(e) => handleSubmitFeedback(e, commandResultMessage)}>
              <div className="flex flex-col gap-y-5 text-sm">
                <input
                  type="text"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="flex-grow flex-shrink-0 flex-basis-0 flex items-center self-stretch box-border border border-solid border-slate-300 rounded-md w-full p-3 placeholder:text-sm placeholder:text-slate-400 text-slate-900 text-sm"
                  placeholder="Please provide feedback on how we can improve..."
                />
                {commentError && <div className="text-red-500">{commentError}</div>}
                <button
                  className="self-end bg-brand-purple text-white py-1.5 px-1 rounded disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-900"
                  type="submit"
                  disabled={
                    feedbackData?.find((feedback) => feedback.key === activeFeedbackId)?.comment !== null &&
                    feedbackData?.find((feedback) => feedback.key === activeFeedbackId)?.comment === comment
                  }
                >
                  Send Feedback
                </button>
              </div>
            </form>
          )}
          {isAuditLogVisible || (!commandResultMessage && auditLogMessages.length > 0) ? (
            <ChatContent
              isParchaUser={false}
              jobStatus={agentRun.status}
              statusMessages={auditLogMessages}
              feedbackData={feedbackData}
              endpointUrl={endpointUrl}
              caseId={agentRun.input_payload.id}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const handleViewInAuditLogClick = (commandResultMessage: StatusMessage) => {
    const elementId = `${commandResultMessage?.content.agent_key}${commandResultMessage?.timestamp}`;
    const element = document.getElementById(elementId);
    const container = document.getElementById("audit-log-container");

    if (element && container) {
      container.scrollTo({
        top: element.offsetTop - 116,
        behavior: "smooth",
      });
    } else {
      setSelectedSidePanelTabValue("audit_log");
      setTimeout(() => {
        const element = document.getElementById(
          `${commandResultMessage?.content.agent_key}${commandResultMessage?.timestamp}`,
        );
        const container = document.getElementById("audit-log-container");
        container.scrollTo({
          top: element.offsetTop - 134,
          behavior: "smooth",
        });
      }, 500);
    }
  };

  const onContactSupportClickHandler = (errorMsg: ErrorMsg | undefined) => {
    const subject = `Bug report: ${errorMsg?.error_type} (${errorMsg?.error_display})`;
    const body = errorMsg?.full_error_message;
    const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body || "no content in the error body...",
    )}`;
    window.open(mailtoLink, "_blank");
  };

  const handleSubmitFeedback = async (e: React.FormEvent, commandResult: CommandResult) => {
    e.preventDefault();

    if (!comment.trim()) {
      setCommentError("Please provide a comment");
      return;
    }

    await sendFeedbackHandler(commandResult, false, comment);
    setActiveFeedbackId(null);
    setComment(null);
  };

  const onThumbsDownClickHandler = (feedbackKey: string) => {
    if (feedbackKey === activeFeedbackId) {
      return;
    }

    setActiveFeedbackId(feedbackKey);
    setComment(null);
    setCommentError(null);
  };

  const onThumbsUpClickHandler = async (commandResult: CommandResult) => {
    await sendFeedbackHandler(commandResult, true);
    setActiveFeedbackId(null);
    setComment(null);
  };

  return (
    <div key={commandInstanceId} className={`${compact ? "p-1" : "p-5 border border-slate-200"} rounded-lg`}>
      <div className="w-full flex flex-col justify-between items-center">
        <Accordion
          expanded={expanded}
          title={renderAccordionTitle(messages)}
          main={renderAccordionMain(messages)}
          content={renderAccordionContent(messages)}
          preventExpandCollapse={!commandResult}
          compact={compact}
        />
      </div>
    </div>
  );
};

export default CheckContainer;
